import { useState, forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Box } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useResponsive } from 'hooks/use-responsive';
import { useDispatch, useSelector } from 'store';
import { signout } from 'store/reducers/auth';
import { logout } from 'api/user';

import HistoryDialogView from 'sections/setting/history';

import ModalBackground from 'assets/images/modal-background.png';
import ModalContentBackground from 'assets/images/modal-content-background.png';
import CloseButton from 'assets/images/buttons/close-button.png';

import LanguageButton from 'assets/images/buttons/language.png';
import HistoryButton from 'assets/images/buttons/history.png';
import LogoutButton from 'assets/images/buttons/logout.png';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type PropsType = {
    status: boolean;
    onClose: () => void;
};

const SettingDialogView = ({ status, onClose }: PropsType) => {
    // const { isMobile } = useResponsive();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [historyModalStatus, setHistoryModalStatus] = useState<boolean>(false);

    const closeModal = () => {
        onClose();
    };

    const handleUserLogout = async () => {
        await logout();

        localStorage.clear();
        dispatch(signout());

        closeModal();
    };

    return (
        <>
            <Dialog
                // fullScreen={isMobile ? true : false}
                open={status}
                TransitionComponent={Transition}
                maxWidth="md"
                fullWidth={true}
                sx={{
                    '& .MuiModal-backdrop': {
                        backdropFilter: "blur(8px)"
                    },
                    '& .MuiDialog-paper': {
                        // ...(isMobile ? { maxHeight: "100%" } : { maxHeight: "80%" }),
                        maxHeight: "80%",
                        width: { xs: "90%" },
                        backgroundColor: "unset",
                        backgroundImage: `url(${ModalBackground})`,
                        backgroundSize: "100% 100%"
                    }
                }}
            >
                <DialogTitle sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 900, textTransform: "uppercase", color: "white", mt: 2 }}>
                    Settings
                    <IconButton onClick={closeModal} sx={{ position: "absolute", right: 2, height: "30px", padding: 0, mr: 2 }}>
                        <Box component="img" src={CloseButton} height={1} sx={{ '&:hover': { filter: "blur(1px)" } }} />
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <Stack
                        direction="row" justifyContent="space-between" alignItems="center" gap={4}
                        sx={{ backgroundImage: `url(${ModalContentBackground})`, backgroundSize: "100% 100%", minHeight: "45vh", padding: 1.2, px: "10%" }}
                    >
                        <Box
                            component="img"
                            src={LanguageButton}
                            height={isMobile ? "50%" : 1}
                            width={isMobile ? "25%" : 1}
                            sx={isMobile ?
                                { cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }
                                :
                                { marginTop: 10, cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }
                            }
                            onClick={() => { console.log(isLoggedIn) }}
                        />

                        <Box
                            component="img"
                            src={HistoryButton}
                            height={isMobile ? "50%" : 1}
                            width={isMobile ? "25%" : 1}
                            sx={isMobile ?
                                { cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }
                                :
                                { marginTop: 10, cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }
                            }
                            onClick={() => { isLoggedIn && setHistoryModalStatus(true) }}
                        />

                        <Box
                            component="img"
                            src={LogoutButton}
                            height={isMobile ? "50%" : 1}
                            width={isMobile ? "25%" : 1}
                            sx={isMobile ?
                                { cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }
                                :
                                { marginTop: 10, cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }
                            }
                            onClick={() => { isLoggedIn && handleUserLogout() }}
                        />
                    </Stack>
                </DialogContent>
            </Dialog>

            <HistoryDialogView status={historyModalStatus} onClose={() => setHistoryModalStatus(false)} />
        </>
    );
};

export default SettingDialogView;