import { useState, ChangeEvent } from "react";
import { Button, TextField } from "@mui/material";
import { UserLoginType } from "types";
import useApi from "hooks/use-api";
import { useDispatch } from 'store';
import { signin } from 'store/reducers/auth';
import { set } from 'store/reducers/modal';

import RoundButtonBackground from 'assets/images/buttons/round-button-background.png';

const LoginView = () => {
    const dispatch = useDispatch();
    const { login } = useApi();
    const [userData, setUserData] = useState<UserLoginType>({
        email: "",
        password: "",
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const userLogin = async () => {
        try {
            const response = await login(userData);
            if (response?.data?.user && response?.data?.token) {
                dispatch(signin(response.data));
                dispatch(set("rule"))
                return;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            {/* <Stack direction="column" gap={4} sx={{ backgroundImage: `url(${ModalContentBackground})`, backgroundSize: "100% 100%", px: 1.2, py: 3, borderRadius: 1 }}> */}
            <TextField
                label="EMAIL"
                variant="standard"
                name="email"
                value={userData.email}
                onChange={handleChange}
                fullWidth
            />
            <TextField
                label="PASSWORD"
                variant="standard"
                type="password"
                name="password"
                value={userData.password}
                onChange={handleChange}
                fullWidth
            />
            {/* </Stack> */}

            {/* <Stack direction="row" gap={1}>
                <Typography color="white">New User? </Typography>
                <Typography color="primary" sx={{ cursor: "pointer" }} onClick={() => dispatch(set("register"))}>Create an account</Typography>
            </Stack> */}

            <Button
                sx={{
                    backgroundImage: `url(${RoundButtonBackground})`,
                    backgroundSize: "100% 100%",
                    width: "200px",
                    color: "#21283b",
                    marginLeft: "auto",
                    fontWeight: 600,
                    '&:hover': {
                        filter: "brightness(120%)"
                    }
                }}
                onClick={userLogin}
            >Login</Button>
        </>
    );
};

export default LoginView;