import React, { useEffect, useRef } from 'react';
import { useSelector } from 'store';

const BackgroundAudio = () => {
    const { volume } = useSelector((state) => state.audio);

    const audioRef = useRef<HTMLAudioElement>(null);

    const playAudio = () => {
        const audio = audioRef.current;
        if (!audio) return;

        audio.play();
    };

    const pauseAudio = () => {
        const audio = audioRef.current;
        if (!audio) return;

        audio.pause();
    };

    useEffect(() => {
        if (volume > 0) {
            playAudio();
        } else {
            pauseAudio();
        }
    }, [volume]);

    return (
        <audio ref={audioRef} src="./backgroundMusic.mp3" loop style={{ display: "none" }} />
    );
};

export default BackgroundAudio;