import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// layouts
import Layout from 'layouts';
// components
import { SplashScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------

const DashboardPage = lazy(() => import('pages/dashboard'));

// ----------------------------------------------------------------------

export const mainRoutes = [
  {
    path: '',
    element: (
      <Suspense fallback={<SplashScreen />}>
        <Layout>
          <Outlet />
        </Layout>
      </Suspense>
    ),
    children: [
      { path: '', element: <DashboardPage />, index: true },
    ],
  },
];
