/* eslint-disable-next-line padded-blocks */
import { combineReducers } from 'redux';

import auth from './auth';
import modal from './modal';
import audio from './audio';

const reducer = combineReducers({
  auth,
  modal,
  audio
});

export default reducer;
