import { forwardRef } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';
// routes
import { RouterLink } from 'routes/components';

import LogoImage from "assets/images/logo.png";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src={LogoImage}
        sx={{ height: 100, cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Box width={1}>
        <Box className="vegastar-logo">
          <Link component={RouterLink} href="/">
            {logo}
          </Link>
        </Box>

        <svg height="0" width="100%">
          <mask id="mask-firefox">
            <image href={LogoImage} filter="url(#filter)" width="100%" />
          </mask>

          <filter id="filter">
            <feFlood floodColor="white" />
            <feComposite in2="SourceAlpha" operator="in" />
          </filter>
        </svg>
      </Box>
    );
  }
);

export default Logo;
