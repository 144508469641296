import { forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Dialog, DialogContent, DialogTitle, DialogActions, Slide, Box, Typography, Stack } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'store';
import { remove } from 'store/reducers/modal';

import ModalBackground from 'assets/images/modal-background.png';
import ModalContentBackground from 'assets/images/modal-content-background.png';
import RoundButtonBackground from 'assets/images/buttons/round-button-background.png';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const RuleDialogView = () => {
    const dispatch = useDispatch();
    const { name } = useSelector(state => state.modal);

    const closeModal = () => {
        dispatch(remove());
    };

    return (
        <Dialog
            fullScreen={isMobile ? true : false}
            open={name === "rule"}
            TransitionComponent={Transition}
            maxWidth="md"
            fullWidth={true}
            sx={{
                '& .MuiModal-backdrop': {
                    backdropFilter: "blur(8px)"
                },
                '& .MuiDialog-paper': {
                    ...(isMobile ? { maxHeight: "100%" } : { maxHeight: "80%" }),
                    backgroundColor: "unset",
                    backgroundImage: `url(${ModalBackground})`,
                    backgroundSize: "100% 100%"
                }
            }}
        >
            <DialogTitle
                sx={isMobile ?
                    { display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 900, textTransform: "uppercase", color: "white" }
                    :
                    { display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 900, textTransform: "uppercase", color: "white", mt: 2 }
                }
            >
                Sweepstakes Rules
            </DialogTitle>

            <DialogContent
                sx={
                    isMobile ? { paddingBottom: 0, marginBottom: 1 } : {}
                }
            >
                <Box
                    sx={{ display: "flex", flexDirection: "column", gap: 4, backgroundImage: `url(${ModalContentBackground})`, backgroundSize: "100% 100%", minHeight: "300px", padding: 1.2, color: "white" }}
                >
                    <Typography variant="h5" fontWeight={800} textTransform="uppercase">Software Disclaimer</Typography>

                    <Typography>Thank you for playing. This software is designed solely for the amusement and entertainment of the players.</Typography>
                    <Typography>By continuing to play, You acknowledge and agree to the following:</Typography>

                    <Stack direction="row" gap={1}>
                        <Typography>{"(1)"}</Typography>
                        <Typography>You are 18years of age:</Typography>
                    </Stack>

                    <Stack direction="row" gap={1}>
                        <Typography>{"(2)"}</Typography>
                        <Typography>You agree that neither the Distributor or Manufacturer of the Software have any liability related to the Software including the redemption of any voucher related to game play:</Typography>
                    </Stack>

                    <Stack direction="row" gap={1}>
                        <Typography>{"(3)"}</Typography>
                        <Typography>You agree that neither the Distributor or the Manufacturer will be liable to you for any loss or damage incurred by you, whether direct, indirect, incidental, special, exemplary or consequential:</Typography>
                    </Stack>

                    <Stack direction="row" gap={1}>
                        <Typography>{"(4)"}</Typography>
                        <Typography>The Distributor, Manufacturer and Retail Operator make no Representations or Warranties, express or implied regarding the Software or game play. Please Play Responsibly.</Typography>
                    </Stack>
                </Box>
            </DialogContent>

            <DialogActions sx={{ justifyContent: "center", alignItems: "center", marginBottom: 1, paddingTop: 0 }}>
                <Button
                    sx={{
                        backgroundImage: `url(${RoundButtonBackground})`,
                        backgroundSize: "100% 100%",
                        width: "170px",
                        color: "#21283b",
                        fontWeight: 600,
                        '&:hover': {
                            filter: "brightness(120%)"
                        }
                    }}
                    onClick={closeModal}
                >I Agree</Button>
            </DialogActions>
        </Dialog>
    );
};

export default RuleDialogView;