import { useState, ChangeEvent } from "react";
import { isMobile } from 'react-device-detect';
import { Button, Grid, TextField } from "@mui/material";

import { changePassword } from "api/user";
import { snackbar } from "utils/alert";

import RoundButtonBackground from 'assets/images/buttons/round-button-background.png';

type ChangePasswordType = {
    currentPassword: string;
    newPassword: string;
    passwordConfirm: string;
};

const PasswordChangeView = ({ closeModal }: { closeModal: () => void }) => {
    const [userData, setUserData] = useState<ChangePasswordType>({
        currentPassword: "",
        newPassword: "",
        passwordConfirm: "",
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const userPasswordChange = async () => {
        try {
            const response = await changePassword(userData);

            if (response?.status) {
                snackbar.success(response.message);

                closeModal();
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <Grid container spacing={1.5}>
                <Grid item xs={12} md={12} pt={isMobile ? 1 : 2}>
                    <TextField
                        label="Current Password"
                        variant="standard"
                        type="password"
                        name="currentPassword"
                        value={userData.currentPassword}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12} pt={isMobile ? 1 : 2}>
                    <TextField
                        label="New Password"
                        variant="standard"
                        type="password"
                        name="newPassword"
                        value={userData.newPassword}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={12} pt={isMobile ? 1 : 2}>
                    <TextField
                        label="Confirm Password"
                        variant="standard"
                        type="password"
                        name="passwordConfirm"
                        value={userData.passwordConfirm}
                        onChange={handleChange}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Button
                sx={{
                    backgroundImage: `url(${RoundButtonBackground})`,
                    backgroundSize: "100% 100%",
                    width: "200px",
                    color: "#21283b",
                    marginLeft: "auto",
                    fontWeight: 600,
                    '&:hover': {
                        filter: "brightness(120%)"
                    }
                }}
                onClick={userPasswordChange}
            >Change</Button>
        </>
    )
}

export default PasswordChangeView;