import { forwardRef, useEffect, useState } from 'react';
import { Button, Divider, Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Box, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useResponsive } from 'hooks/use-responsive';
import { fCurrency } from 'utils/format-number';
import { getTransactionHistory } from 'api/user';

import ModalBackground from 'assets/images/modal-background.png';
import ModalContentBackground from 'assets/images/modal-content-background.png';
import CloseButton from 'assets/images/buttons/close-button.png';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type PropsType = {
    status: boolean;
    onClose: () => void;
};

const HistoryDialogView = ({ status, onClose }: PropsType) => {
    const { isMobile } = useResponsive();

    const [histories, setHistories] = useState([]);

    const closeModal = () => {
        onClose();
    };

    const getResult = async () => {
        const response = await getTransactionHistory();

        if (response.status) {
            setHistories(response.data);
        }
    };

    useEffect(() => {
        status && getResult();
    }, [status]);

    return (
        <Dialog
            fullScreen={isMobile ? true : false}
            open={status}
            TransitionComponent={Transition}
            maxWidth="sm"
            fullWidth={true}
            sx={{
                '& .MuiModal-backdrop': {
                    backdropFilter: "blur(8px)"
                },
                '& .MuiDialog-paper': {
                    maxHeight: "80%",
                    // width: { xs: "90%" },
                    backgroundColor: "unset",
                    backgroundImage: `url(${ModalBackground})`,
                    backgroundSize: "100% 100%"
                }
            }}
        >
            <DialogTitle sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 900, textTransform: "uppercase", color: "white", mt: 2 }}>
                Transaction History
                <IconButton onClick={closeModal} sx={{ position: "absolute", right: 2, height: "30px", padding: 0, mr: 2 }}>
                    <Box component="img" src={CloseButton} height={1} sx={{ '&:hover': { filter: "blur(1px)" } }} />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Stack
                    direction="column" gap={2}
                    sx={{ backgroundImage: `url(${ModalContentBackground})`, backgroundSize: "100% 100%", minHeight: "50vh", height: "95%", padding: 1.2, px: "10%" }}
                >
                    {histories.length && histories.map((history: any, index: number) => <Stack key={index} direction="row" justifyContent="space-between" sx={{ color: "white" }}>
                        <Typography variant='h6'>{history.createdAt}</Typography>
                        <Typography variant='h6'>{fCurrency(history.amount)}</Typography>
                    </Stack>)}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default HistoryDialogView;