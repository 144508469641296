import { forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Box, Stack } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'store';
import { remove } from 'store/reducers/modal';

import LoginView from 'sections/auth/login';
import PasswordChangeView from 'sections/auth/password-change';

import ModalBackground from 'assets/images/modal-background.png';
import ModalContentBackground from 'assets/images/modal-content-background.png';
import CloseButton from 'assets/images/buttons/close-button.png';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AuthDialogView = () => {
    const dispatch = useDispatch();
    // const { isMobile } = useResponsive();
    const { name } = useSelector(state => state.modal);

    const closeModal = () => {
        dispatch(remove());
    };

    return (
        <Dialog
            // fullScreen={isMobile ? true : false}
            open={name !== ""}
            TransitionComponent={Transition}
            maxWidth="xs"
            fullWidth={true}
            sx={{
                '& .MuiModal-backdrop': {
                    backdropFilter: "blur(8px)"
                },
                '& .MuiDialog-paper': {
                    ...(isMobile ? { maxHeight: "100%" } : { maxHeight: "80%" }),
                    width: { xs: "90%" },
                    backgroundColor: "unset",
                    backgroundImage: `url(${ModalBackground})`,
                    backgroundSize: "100% 100%"
                }
            }}
        >
            <DialogTitle
                variant={isMobile ? "h6" : "h5"}
                sx={isMobile ?
                    { display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 900, textTransform: "uppercase", color: "white", mt: 0 }
                    :
                    { display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 900, textTransform: "uppercase", color: "white", mt: { xs: 0, sm: 2 } }
                }
            >
                {name === "login" ? "LOG IN" : name === "register" ? "Register" : "Password Change"}
                <IconButton
                    onClick={closeModal}
                    sx={isMobile ?
                        { position: "absolute", right: 2, height: "20px", padding: 0, mr: 2 }
                        :
                        { position: "absolute", right: 2, height: { xs: "20px", sm: "30px" }, padding: 0, mr: 2 }
                    }
                >
                    <Box component="img" src={CloseButton} height={1} sx={{ '&:hover': { filter: "blur(1px)" } }} />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Stack
                    direction={"column"}
                    sx={isMobile ?
                        { gap: 3, backgroundImage: `url(${ModalContentBackground})`, backgroundSize: "100% 100%", px: 1.2, py: 2, borderRadius: 1 }
                        :
                        { gap: { xs: 3, sm: 5 }, backgroundImage: `url(${ModalContentBackground})`, backgroundSize: "100% 100%", px: 1.2, py: { xs: 2, sm: 3 }, borderRadius: 1 }
                    }
                >
                    {name === "login" && <LoginView />}
                    {name === "password-change" && <PasswordChangeView closeModal={closeModal} />}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default AuthDialogView;