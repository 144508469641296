import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import AuthGuard from 'utils/auth-guard';
// layouts
import Layout from 'layouts';
// components
import { SplashScreen } from 'components/loading-screen';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export const authRoutes = [
  {
    path: '',
    element: (
      <AuthGuard>
        <Suspense fallback={<SplashScreen />}>
          <Layout>
            <Outlet />
          </Layout>
        </Suspense>
      </AuthGuard>
    ),
    children: [
      // { path: 'profile', element: <UserProfile /> },
    ],
  },
];
