import { Navigate, useRoutes } from 'react-router-dom';
// config
import { DASHBOARD_PATH } from 'config-global';
//
import { authRoutes } from './authRoutes';
import { mainRoutes } from './mainRoutes';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={DASHBOARD_PATH} replace />,
    },

    // Main routes
    ...mainRoutes,

    // Auth routes
    ...authRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/" replace /> },
  ]);
}
