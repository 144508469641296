import React from 'react';
import { useSelector } from 'store';

export interface ButtonClickAudioRef {
    playAudio: () => void;
}

const ButtonClickAudio = React.forwardRef<ButtonClickAudioRef>((props, ref) => {
    const { volume } = useSelector((state) => state.audio);

    const playAudio = () => {
        // Play audio logic
        const audio = new Audio('./btnClick.mp3');
        audio.volume = volume || 0;
        audio.play();
    };

    React.useImperativeHandle(ref, () => ({
        playAudio: () => {
            playAudio();
        },
    }));

    return (
        <audio src="./btnClick.mp3" style={{ display: "none" }} />
    );
});

export default ButtonClickAudio;
