import axios from 'utils/axios';
import { API_PATH } from "config-global";

// ----------------------------------------------------------------------

export const logout = async () => {
  const URL = API_PATH.AUTH_LOGOUT;

  const response = await axios.post(URL);
  return response.data;
}

export const getUserData = async () => {
  const URL = API_PATH.USER_GET_DATA;

  const response = await axios.post(URL);
  return response.data;
}

export const changePassword = async (payload: any) => {
  const URL = API_PATH.USER_PASSWORD_CHANGE;

  const response = await axios.post(URL, payload);
  return response?.data;
}

export const getTransactionHistory = async () => {
  const URL = API_PATH.USER_TRANSACTION_HISTORY;

  const response = await axios.post(URL);
  return response?.data;
}