import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box, { BoxProps } from '@mui/material/Box';
//
import Logo from 'components/logo';

import LogoIcon from 'assets/images/favicon.png';

// ----------------------------------------------------------------------

export default function SplashScreen({ sx, ...other }: BoxProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  // if (!mounted) {
  //   return null;
  // }

  return (
    <Box
      sx={{
        right: 0,
        width: 1,
        bottom: 0,
        height: 1,
        zIndex: 9998,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        // bgcolor: 'background.default',
        // bgcolor: 'rgb(3, 16, 31)',
        ...sx,
      }}
      {...other}
    >
      <m.div
        animate={{
          scale: [1, 1.9, 1.9, 1, 1],
          opacity: [1, 0.48, 0.48, 1, 1],
        }}
        transition={{
          duration: 1,
          ease: 'easeInOut',
          repeatDelay: 1,
          repeat: Infinity,
        }}
      >
        <Box
          component='img'
          src={LogoIcon}
          width='100px'
          height='100px'
        />
      </m.div>
      {/* <Logo sx={{ height: { xs: "70px", sm: "100px" } }} /> */}
    </Box>
  );
}
