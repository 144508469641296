import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  volume: number;
};

const initialState: initialStateType = {
  volume: 0
};

const audio = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    turnOn(state, action) {
      state.volume = action.payload;
    },

    turnOff(state) {
      state.volume = 0;
    },
  },
});

export default audio.reducer;

export const { turnOn, turnOff } = audio.actions;
