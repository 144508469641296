import { ReactNode, useCallback, useEffect } from "react";
import { io } from "socket.io-client";
import { Stack } from "@mui/material";
import { useDispatch, useSelector } from "store";
import { edit } from 'store/reducers/auth';
import { set } from 'store/reducers/modal';
import { setFullScreen } from "utils/set-screen";
import { API_PATH, SOCKET_URL } from "config-global";
import axios from 'utils/axios';

import Header from "layouts/header";

import MobileDialogView from "sections/mobile";
import AuthDialogView from "sections/auth";
import RuleDialogView from "sections/auth/rules";

import Background from "assets/images/background.gif";

const Layout = ({ children }: { children: ReactNode }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const checkAuthStatus = useCallback(async () => {
    const res = await axios.post(API_PATH.AUTH_CHECK);
    if (!res?.data) {
      dispatch(set("login"));
    }
    return dispatch(edit(!res?.data ? {} : res.data));
  }, [dispatch]);

  useEffect(() => {
    const socket = io(`${SOCKET_URL}`);

    if (isLoggedIn) {
      socket.emit('newPlayer', user);

      socket.on('checkBalance', () => {
        checkAuthStatus();
      });
    } else {
      socket.disconnect();
    }

    return () => {
      socket.off('checkBalance');
    };
  }, [isLoggedIn, user, dispatch, checkAuthStatus]);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        backgroundImage: `url(${Background})`,
        backgroundSize: "100% 100%",
        touchAction: "pan-x"
      }}
      onClick={setFullScreen}
    >
      <Header />

      {children}

      <MobileDialogView />

      <AuthDialogView />
      <RuleDialogView />

      {/* <AddToHomeScreen /> */}
    </Stack>
  );
};

export default Layout;
