import { useMediaQuery } from "@mui/material";

export const useResponsive = () => {
    const isMobile = useMediaQuery("(max-width:600px)");
    const isDesktop = useMediaQuery("(min-width:601px)");
    const isSDesktop = useMediaQuery("(min-width:601px) and (max-width:900px)");
    const isMDesktop = useMediaQuery("(min-width:901px) and (max-width:1150px)");
    const isLDesktop = useMediaQuery("(min-width:1151px)");

    return {
        isMobile,
        isDesktop,
        isSDesktop,
        isMDesktop,
        isLDesktop
    };
}